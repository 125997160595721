<template>
  <div>
    <TabView>
      <TabPanel header="Lohnkontrolle Person">
        <h2>Personen</h2>
        <p>Dies ist die Resultatseite der Lohnkontrolle.<br>Sie zeigt die berechneten Auszahlungen pro Personalnummer an
          und vergleicht diese mit den Werten der hochgeladenen Excel-Dateien für den angegebenen Monat.</p>
        <div>
          <Button icon="pi pi-external-link" label="Export" @click="exportCSVPerson($event)" />
        </div>
        <br>
        <!-- Data Table from PrimeVue, Presorted for false Lohnkontrolle-->
        <DataTable ref="dtPerson" v-model:filters="filters" :value="store.ResultPerson" striped-rows
          responsive-layout="scroll" :paginator="true" :rows="10" sort-field="lohnkorrekt" :sort-order="1"
          filter-display="menu" :global-filter-fields="[
              'persnumber',
              'persname',
              'perstype',
              'perskostenstelle',
              'lohnkorrekt',
            ]">
          <template #header>

            <div class="flex justify-content-between">
              <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined"
                @click="clearFilter()" />
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
              </span>
            </div>
          </template>
          <template #empty> No person found. </template>

          <Column field="persnumber" header="Nummer" :sortable="true"></Column>
          <Column field="persname" header="Name"></Column>
          <Column field="perstype" header="Typ"></Column>
          <Column field="perskostenstelle" header="Kostenstelle" :sortable="true"></Column>
          <Column field="lunchkorrekt" header="LunchCheck" :sortable="true">
            <template #body="slotProps">
              <i v-if="slotProps.data.lunchkorrekt" :class="'pi pi-check status-' + slotProps.data.lunchkorrekt"
                style="font-size: 2rem"></i>
              <i v-else-if="slotProps.data.lunchkorrekt === undefined"
                :class="'pi pi-minus status-' + slotProps.data.lunchkorrekt" style="font-size: 2rem"></i>
              <i v-else :class="'pi pi-times status-' + slotProps.data.lunchkorrekt" style="font-size: 2rem"></i>
            </template>
          </Column>
          <Column field="perssozial" header="Sozialabgaben"></Column>
          <Column field="perslohnFranken" header="Lohn Excel"></Column>
          <Column field="perslohnCalculatedFranken" header="Lohn SAP"></Column>
          <Column field="lohnkorrekt" header="Lohn Check" :sortable="true">
            <template #body="slotProps">
              <i v-if="slotProps.data.lohnkorrekt" :class="'pi pi-check status-' + slotProps.data.lohnkorrekt"
                style="font-size: 2rem"></i>
              <i v-if="!slotProps.data.lohnkorrekt" :class="'pi pi-times status-' + slotProps.data.lohnkorrekt"
                style="font-size: 2rem"></i>
            </template>
          </Column>
          <template #footer>
            <p v-if="$refs.dtPerson">
              Total Einträge :
              {{ $refs.dtPerson.processedData.length }}
            </p>
            <p v-if="notMounted">
              Total Einträge :
              {{ store.ResultPerson.length }}
            </p>
          </template>
        </DataTable>
      </TabPanel>
      <TabPanel header="Sozialleistung setzten">
        <h2>Sozialabgaben</h2>
        <p>Diese Seite zeigt die aus dem SAP berechneten Sozialabgaben pro Kostenstelle an.<br>Auf Knopfdruck werden die
          Sozialabgaben in den hochgeladenen Excel-Dateien für diesen Monat mit diesen Werten überschrieben und als
          download angeboten.<br>Es ist wichtig die heruntergelandenen Dateien danach zu kontrollieren.</p>
        <div>
          <Button @click="alert('Diese Funktion muss erst noch programmiert werden')">Excel Dateien generieren und
            downloaden</Button>
        </div>
        <br />
        <DataTable ref="dtKostenstelle" :value="KSTArray" striped-rows responsive-layout="scroll" :paginator="true"
          :rows="10">
          <!--
          <template #header>
            <div style="text-align: left">
              <Button icon="pi pi-external-link" label="Export" @click="exportCSVKostenstelle($event)" />
            </div>
          </template>
-->
          <template #empty> No Kostenstelle found. </template>

          <Column field="Kostenstelle" header="Kostenstelle" :sortable="true"></Column>
          <Column field="SLCalculated" header="SAP Berechnet"></Column>
          <!-- <Column field="SLManuell" header="Momentan im Excel"></Column>-->
          <!--
          <Column field="Check" header="Check" :sortable="true">
            <template #body="slotProps">
              <i v-if="slotProps.data.Check" :class="'pi pi-check status-' + slotProps.data.Check"
                style="font-size: 2rem"></i>
              <i v-if="!slotProps.data.Check" :class="'pi pi-times status-' + slotProps.data.Check"
                style="font-size: 2rem"></i>
            </template>
          </Column>
    -->
          <template #footer>
            Total
            {{ KSTArray ? KSTArray.length : 0 }}
            Kostenstellen
          </template>
        </DataTable>

      </TabPanel>
      <TabPanel header="Berechnungseinstellungen">
        <h4>Berechnungseinstellungen</h4>
        <p>Diese Seite zeigt an, welche Kostenstellen für diese Berechnung addiert wurden.<br>Diese können in den
          Einstellungen verändert werden.</p>
        <p>Lohnarten Lohn ATP</p>
        <div>{{ store.lohnCheckerATP }}</div>
        <br />
        <p>Lohnarten Lohn WP</p>
        <div>{{ store.lohnCheckerWP }}</div>
        <br />
        <p>Lohnarten Lohn Quali</p>
        <div>{{ store.lohnCheckerQuali }}</div>
        <br />
        <p>Lohnarten Sozialleistung ATP</p>
        <div>{{ store.sozialCheckerATP }}</div>
        <br />
        <p>Lohnarten Sozialleistung WP</p>
        <div>{{ store.sozialCheckerWP }}</div>
        <br />
        <p>Lohnarten Sozialleistung Quali</p>
        <div>{{ store.sozialCheckerQuali }}</div>
      </TabPanel>
      <TabPanel header="Alle internen Programmdaten">
        <h2>Alle internen Programmdaten</h2>
        <p>Diese Seite ist für die Entwickler gedacht und zeigt alle programminternen Daten an zur
          Rückverfolgung/Bugfixing </p>
        <PersonResultTable />
        <br /><br /><br /><br />
        <h2>KSTArray</h2>
        <div>{{ KSTArray }}</div>
      </TabPanel>
      <TabPanel header="Neue Berechnung">
        <div>
          <Button @click="newProject()">Neue Berechnung öffnen</Button>
        </div>
      </TabPanel>
    </TabView>


  </div>
</template>

<script>
import { store } from "@/store/store";
import PersonResultTable from "@/components/PersonResultTable.vue";
import { FilterMatchMode } from "primevue/api";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';

export default {
  components: {
    PersonResultTable,
    TabView,
    TabPanel
  },
  data() {
    return {
      store,
      filters: null,
      KSTArray: [],
      notMounted: true,
      beforeUnloadListener: (event) => {
        event.preventDefault();
        return (event.returnValue = "Are you sure you want to exit?");
      },
    };
  },
  created() {
    this.initFilters();
    this.KSTArray = Object.values(store.sozialabgabenKSTObject);

    window.addEventListener("beforeunload", this.beforeUnloadListener, {
      capture: true,
    });

    const makeUnique = (array = [], keys = []) => {
      if (!keys.length || !array.length) return [];

      return array.reduce((list, item) => {
        const hasItem = list.find((listItem) =>
          keys.every((key) => listItem[key] === item[key])
        );
        if (!hasItem) list.push(item);
        return list;
      }, []);
    };

    let unique = makeUnique(store.ResultPerson, [
      "persnumber",
      "perskostenstelle",
      "persname",
    ]);

    store.ResultPerson = unique;
  },
  mounted() {
    this.FilterResultLenght = this.$refs.dtPerson.processedData.length;
    this.notMounted = false;
  },
  methods: {
    newProject() {
      const routeData = this.$router.resolve({
        name: "Files",
      });
      window.open(routeData.href, "_blank");
    },
    exportCSVPerson() {
      this.$refs.dtPerson.exportCSV();
    },
    exportCSVKostenstelle() {
      this.$refs.dtKostenstelle.exportCSV();
    },
    clearFilter() {
      this.initFilters();
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>

<style scoped>
.status-true {
  color: teal;
}

.status-false {
  color: red;
}</style>
