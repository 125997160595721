import { store } from "@/store/store";
import * as XLSX from "xlsx/xlsx.mjs";

// This file loader simply loades the file contents into the storage in an appropriate format for further processing by this application

// we load the SAP Dump File
export function preprocessSAPFile(file: File) {
  return new Promise((resolve, reject) => {
    try {
      // loading with SheetJS

      const reader = new FileReader();
      reader.onload = function (e) {
        if (e.target) {
          const data = e.target.result;
          const workbook = XLSX.read(data);
          const rowArray = XLSX.utils.sheet_to_row_object_array(
            workbook.Sheets[workbook.SheetNames[0]]
          );

          // the unique internal data points variables
          const persNumbers: number[] = [];
          const lohnarten: string[] = [];

          rowArray.forEach((row: any) => {
            //vue store object
            store.SAP.push(row);

            // unique internal data points
            // clean up, no null or undefined or empty strings. Some null values have made it into the database in the past
            if (
              row["Lohnart"] !== null &&
              row["Lohnart"] !== undefined &&
              row["Lohnart"] !== ""
            ) {
              lohnarten.push(row["Lohnart"]);
            }
            if (
              row["Pers.Nr."] !== null &&
              row["Pers.Nr."] !== undefined &&
              row["Pers.Nr."] !== ""
            ) {
              persNumbers.push(row["Pers.Nr."]);
            }
          });

          // now uniqueifing the arrays to only contain one element of each unique element
          store.UniquePersonNumbers = [...new Set(persNumbers)];
          store.SAPLohnart = [...new Set(lohnarten)];

          // its done
          resolve(true);
        }
      };
      reader.readAsArrayBuffer(file);
    } catch {
      reject("Error in loadfile SAP Dump");
    }
  });
}

export function preprocessExcelFile(file: File) {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = function (e) {
        if (e.target) {
          const data = e.target.result;
          /* reader.readAsArrayBuffer(file) -> data will be an ArrayBuffer */
          const workbook = XLSX.read(data);

          const rowArray = XLSX.utils.sheet_to_row_object_array(
            workbook.Sheets[workbook.SheetNames[0]],
            {
              header: 1,
            }
          );

          // we parse the for us imporant rows of this file. We do this by only looking at the rows that include a personnumber
          let numberOfPersonsFound = 0;
          rowArray.forEach((row: any) => {
            if (Object.values(row).includes("SL ATP")) {
              const key = row[0] + "_" + row[5];
              store.SLATP[key] = row;
            } else if (Object.values(row).includes("SL WP")) {
              const key = row[0] + "_" + row[5];
              store.SLWP[key] = row;
            } else if (Object.values(row).includes("SL MB")) {
              const key = row[0] + "_" + row[5];
              store.SLQUALI[key] = row;
            }

            if (!isNaN(row[0]) && row[0] > 0) {
              store.Data.push(row);
              numberOfPersonsFound += 1;

              if (!store.UniquePersonNumbers.includes(row[0])) {
                store.UniquePersonNumbers.push(row[0]);
              }
            }

            // they do not know the person number of a person so they put a 0 in it
            if (!isNaN(row[0]) && row[0] === 0) {
              console.log("Found a zero Person:");
              console.log(row);
              store.zeroPerson.push(row);
              numberOfPersonsFound += 1;
            }

            /*
            store.UniquePersonNumbers.forEach((personnumber) => {
              if (Object.values(row).includes(personnumber)) {
                store.Data.push(row);
              }
            });
*/
          });

          console.log("Number of Persons Found");
          console.log(numberOfPersonsFound);

          // Zusammenführen, wenn dieselber Person mehrmals in derselben Kostenstelle vorkommt weil es zwischenzeitlich (z.B. im Monat April) eine Lohnerhöhung gab
          // Heisst sie ist zwei oder mehr Rows drin, wobei bei den einten Monaten nichts als Lohnt aufgeführt ist während es in der anderen Spalte ist. Wir vereinigen diese Rows zu einer
          // damit wir auch die Sozialabgaben richtig berechnen
          // wir suchen zuerst ob dieselbe person mehrmals in den Daten vorkommt
          store.UniquePersonNumbers.forEach((personnumber) => {
            let numberOfAppearances = 0;
            const entries: any = [];
            let personMultipleEntries: string | any[] = [];
            store.Data.forEach((entry) => {
              if (entry[0] === personnumber && entry[3] != "Lunch") {
                numberOfAppearances += 1;
                entries.push(entry);
              }
            });
            // falls die Person mehrmals in den Daten vorkommt vergleichen wir nun diese Einträge ob wie Einträge finden die dieselben Kostenstelle haben
            if (numberOfAppearances > 1) {
              entries.forEach((element: any[]) => {
                const kostenstelle = element[5];
                let multipleEntries = 0;
                const innerEntries: any = [];
                entries.forEach((innerElement: any[]) => {
                  if (innerElement[5] == kostenstelle) {
                    multipleEntries += 1;
                    innerEntries.push(innerElement);
                  }
                });
                if (multipleEntries > 1) {
                  // we overwrite it, so that only the last result stays. Otherwise we would push all the possible combinations on it, but this way we only keep the last result which means all the entries that have the same kostenstelle from the same person but only unique entries
                  personMultipleEntries = innerEntries;
                }
              });
            }
            if (personMultipleEntries.length > 0) {
              console.log("Removing multiple entries");
              console.log(personMultipleEntries);
              // so now we need to combine those entries into one, and then also remove those two entries from the store and add the combined one
              // first we basically clone the first entry so we do not need to start from scratch
              const newEntry = personMultipleEntries[0];
              // now we can compare the actual rows that would contain 0 if there was no value provided, we then check all the other entries, if one has a number we take it, otherwise we leave it
              for (let i = 6; i <= 29; i++) {
                if (newEntry[i] == 0 || !newEntry[i]) {
                  personMultipleEntries.forEach((entry) => {
                    if (!isNaN(entry[i])) {
                      newEntry[i] = entry[i];
                    }
                  });
                }
              }
              //remove previous entries
              personMultipleEntries.forEach((element) => {
                for (let i = 0; i < store.Data.length; i++) {
                  if (
                    store.Data[i][0] === element[0] &&
                    store.Data[i][5] === element[5]
                  ) {
                    store.Data.splice(i, 1);
                    i--;
                  }
                }
              });
              // add new entry
              store.Data.push(newEntry);
            }
          });

          resolve(true);
        }
      };
      reader.readAsArrayBuffer(file);
    } catch {
      reject("Error in loadfile Excel File " + file.name);
    }
  });
}
