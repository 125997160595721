import { ResultPersonObject } from "@/interfaces/ResultPersonObject";
import { SAPEntry } from "@/interfaces/SAPEntry";
import { store } from "../store/store";
import { indexedObject } from "@/interfaces/indexedObject";

export default function calculate() {
  return new Promise((resolve, reject) => {
    try {
      // rounding with normal rounding, can be adjusted if we always round up or something
      function gerundet(amt: number, prec: number) {
        // amt ist der zu rundende Betrag
        // prec ist die Genauigkeit (zb 0.05 um auf 5 rappen zu runden)
        return Math.round(amt / prec) * prec;
      }

      //variables definitions
      let persnumber: number;
      let persname: string;
      let persart: string; // Lohn oder Lunchcheck
      let perstype: string; // ATP oder WP oder Quali
      let perskostenstelle: string;
      let perspensum: number;
      let perslohn: number; // manuelle liste
      let perslohnCalculated: number; // von SAP berechnet
      let perslohnkorrekt: boolean;
      let perslunch: number;
      let perslunchSAP: number;
      let perslunchKorrekt: boolean;
      let perslohnrappen: number; // manuelle liste auber auf 5 rappen gerundet
      let perslohnfranken: number; // manuelle liste aber auf franken gerundet
      let perslohnCalculatedFranken: number; //  von SAP berechnet aber auf Franken gerundet
      let perssozial: number; // the sozial of each person for each kostenstelle since im already calculating it why not it will help them
      let kostenstellesozialatp: number; // at the end we take the store object for each individual kostenstelle and add this value as kumulative but it
      let kostenstellesozialwp: number; // at the end we take the store object for each individual kostenstelle and add this value as kumulative but it
      let kostenstellesozialquali: number; // at the end we take the store object for each individual kostenstelle and add this value as kumulative but it

      store.Data.forEach((row) => {
        persnumber = row[0];
        persname = row[1];
        persart = row[3];
        perstype = row[4];
        perskostenstelle = row[5];
        perslohnCalculated = 0;
        perslohnrappen = 0;
        perssozial = 0;

        //lohn oder lunchcheck?
        if (persart == "Lohn") {
          if (store.Month == "Januar") {
            perspensum = row[6];
            perslohn = row[7];
          }
          if (store.Month == "Februar") {
            perspensum = row[8];
            perslohn = row[9];
          }
          if (store.Month == "März") {
            perspensum = row[10];
            perslohn = row[11];
          }
          if (store.Month == "April") {
            perspensum = row[12];
            perslohn = row[13];
          }
          if (store.Month == "Mai") {
            perspensum = row[14];
            perslohn = row[15];
          }
          if (store.Month == "Juni") {
            perspensum = row[16];
            perslohn = row[17];
          }
          if (store.Month == "Juli") {
            perspensum = row[18];
            perslohn = row[19];
          }
          if (store.Month == "August") {
            perspensum = row[20];
            perslohn = row[21];
          }
          if (store.Month == "September") {
            perspensum = row[22];
            perslohn = row[23];
          }
          if (store.Month == "Oktober") {
            perspensum = row[24];
            perslohn = row[25];
          }
          if (store.Month == "November") {
            perspensum = row[26];
            perslohn = row[27];
          }
          if (store.Month == "Dezember") {
            perspensum = row[28];
            perslohn = row[29];
          }

          perslohnrappen = parseFloat(gerundet(perslohn, 0.05).toFixed(2));
          if (isNaN(perslohn)) {
            perslohn = 0;
          }
          perslohnfranken = Math.trunc(perslohn);

          // get the SAP values
          if (perstype == "ATP") {
            store.SAP.forEach((entry: SAPEntry) => {
              if (
                (Object.values(entry).includes(persnumber) &&
                  Object.values(entry).includes(perskostenstelle)) ||
                (Object.values(entry).includes(persnumber) &&
                  Object.values(entry).includes(parseInt(perskostenstelle)))
              ) {
                // lohn
                store.lohnCheckerATP.forEach((selectedLohnart) => {
                  if (entry.Lohnart == selectedLohnart) {
                    perslohnCalculated = parseFloat(
                      (
                        perslohnCalculated + entry["Betrag aktuell (0008)"]
                      ).toFixed(2)
                    );
                  }
                });

                // sozial
                store.sozialCheckerATP.forEach((selectedLohnart) => {
                  if (entry.Lohnart == selectedLohnart) {
                    perssozial = parseFloat(
                      (perssozial + entry["Betrag aktuell (0008)"]).toFixed(2)
                    );
                  }
                });
              }
            });
          } else if (perstype == "WP") {
            store.SAP.forEach((entry: SAPEntry) => {
              if (
                (Object.values(entry).includes(persnumber) &&
                  Object.values(entry).includes(perskostenstelle)) ||
                (Object.values(entry).includes(persnumber) &&
                  Object.values(entry).includes(parseInt(perskostenstelle)))
              ) {
                // lohn
                store.lohnCheckerWP.forEach((selectedLohnart) => {
                  if (entry.Lohnart == selectedLohnart) {
                    perslohnCalculated = parseFloat(
                      (
                        perslohnCalculated + entry["Betrag aktuell (0008)"]
                      ).toFixed(2)
                    );
                  }
                });

                // sozial
                store.sozialCheckerWP.forEach((selectedLohnart) => {
                  if (entry.Lohnart == selectedLohnart) {
                    perssozial = parseFloat(
                      (perssozial + entry["Betrag aktuell (0008)"]).toFixed(2)
                    );
                  }
                });
              }
            });
          } else if (perstype == "MB") {
            store.SAP.forEach((entry: SAPEntry) => {
              if (
                (Object.values(entry).includes(persnumber) &&
                  Object.values(entry).includes(perskostenstelle)) ||
                (Object.values(entry).includes(persnumber) &&
                  Object.values(entry).includes(parseInt(perskostenstelle)))
              ) {
                // lohn
                store.lohnCheckerQuali.forEach((selectedLohnart) => {
                  if (entry.Lohnart == selectedLohnart) {
                    perslohnCalculated = parseFloat(
                      (
                        perslohnCalculated + entry["Betrag aktuell (0008)"]
                      ).toFixed(2)
                    );
                  }
                });

                // sozial
                store.sozialCheckerQuali.forEach((selectedLohnart) => {
                  if (entry.Lohnart == selectedLohnart) {
                    perssozial = parseFloat(
                      (perssozial + entry["Betrag aktuell (0008)"]).toFixed(2)
                    );
                  }
                });
              }
            });
          } else {
            console.error(
              "Could not find person type ATP/WP/Quali. Found: " + perstype
            );
          }

          perslohnCalculatedFranken = Math.trunc(perslohnCalculated);

          if (
            perslohnCalculatedFranken == perslohnfranken ||
            (perslohnCalculatedFranken - perslohnfranken <= 3 &&
              perslohnCalculatedFranken - perslohnfranken >= -3)
          ) {
            perslohnkorrekt = true;
          } else {
            perslohnkorrekt = false;
          }

          // check if person object already exists (we calculated lunch before)
          const found = store.ResultPerson.some(
            (el) =>
              el.persnumber === persnumber &&
              el.perskostenstelle === perskostenstelle &&
              el.persname === persname
          );

          if (!found) {
            // generate person object
            const PersonObject: ResultPersonObject = {
              persnumber: persnumber,
              persname: persname,
              persart: persart,
              perstype: perstype,
              perslohn: perslohn,
              perslohnCalculated: perslohnCalculated,
              perskostenstelle: perskostenstelle,
              perspensumSAP: perspensum,
              lohnkorrekt: perslohnkorrekt,
              perslohnRappen: perslohnrappen,
              perslohnFranken: perslohnfranken,
              perslohnCalculatedFranken: perslohnCalculatedFranken,
              perssozial: perssozial,
            };
            // save
            store.ResultPerson.push(PersonObject);
          } else {
            // update personobject with lohn
            const result = store.ResultPerson.find(
              (el) =>
                el.persnumber === persnumber &&
                el.perskostenstelle === perskostenstelle &&
                el.persname === persname
            );

            if (result) {
              // the Person Object
              const PersonObject: ResultPersonObject = result;

              /*
              // we remove that entry from the store by creating a new array that does not contain that entry and overwrite the store with that otherwise it will be double in the store
              const storeObjectWithEntryRemoved = store.ResultPerson.filter(
                (el) =>
                  el.persnumber !== persnumber &&
                  el.perskostenstelle === perskostenstelle
              );
              store.ResultPerson = storeObjectWithEntryRemoved;
              */

              // now we update the previous retrieved Object and then add it to the storeObject
              PersonObject.perslohn = perslohn;
              PersonObject.perslohnCalculated = perslohnCalculated;
              PersonObject.lohnkorrekt = perslohnkorrekt;
              PersonObject.perslohnRappen = perslohnrappen;
              PersonObject.perslohnFranken = perslohnfranken;
              PersonObject.perslohnCalculatedFranken =
                perslohnCalculatedFranken;
              PersonObject.perssozial = perssozial;
              // save
              store.ResultPerson.push(PersonObject);
            }
          }
        } else if (persart == "Lunch") {
          // calculate lunchcheck
          // we dont really calculate pensum

          if (store.Month == "Januar") {
            perspensum = row[6];
            perslunch = row[7];
          }
          if (store.Month == "Februar") {
            perspensum = row[8];
            perslunch = row[9];
          }
          if (store.Month == "März") {
            perspensum = row[10];
            perslunch = row[11];
          }
          if (store.Month == "April") {
            perspensum = row[12];
            perslunch = row[13];
          }
          if (store.Month == "Mai") {
            perspensum = row[14];
            perslunch = row[15];
          }
          if (store.Month == "Juni") {
            perspensum = row[16];
            perslunch = row[17];
          }
          if (store.Month == "Juli") {
            perspensum = row[18];
            perslunch = row[19];
          }
          if (store.Month == "August") {
            perspensum = row[20];
            perslunch = row[21];
          }
          if (store.Month == "September") {
            perspensum = row[22];
            perslunch = row[23];
          }
          if (store.Month == "Oktober") {
            perspensum = row[24];
            perslunch = row[25];
          }
          if (store.Month == "November") {
            perspensum = row[26];
            perslunch = row[27];
          }
          if (store.Month == "Dezember") {
            perspensum = row[28];
            perslunch = row[29];
          }

          store.SAP.forEach((entry: SAPEntry) => {
            if (
              (Object.values(entry).includes(persnumber) &&
                Object.values(entry).includes(perskostenstelle)) ||
              (Object.values(entry).includes(persnumber) &&
                Object.values(entry).includes(parseInt(perskostenstelle)))
            ) {
              if (entry.Lohnart == "1462") {
                perslunchSAP = entry["Betrag aktuell (0008)"];
              }

              store.lohnCheckerWP.forEach((selectedLohnart) => {
                if (entry.Lohnart == selectedLohnart) {
                  perslohnCalculated =
                    perslohnCalculated + entry["Betrag aktuell (0008)"];
                }
              });
            }
          });

          if (perslunch == perslunchSAP) {
            perslunchKorrekt = true;
          } else {
            perslunchKorrekt = false;
          }

          // check if person object already exists (we calculated lohn before)
          const found = store.ResultPerson.some(
            (el) =>
              el.persnumber === persnumber &&
              el.perskostenstelle === perskostenstelle
          );
          if (!found) {
            // generate person object
            const PersonObject: ResultPersonObject = {
              persnumber: persnumber,
              persname: persname,
              persart: persart,
              perstype: perstype,
              perskostenstelle: perskostenstelle,
              perspensumSAP: perspensum,
              perslunch: perslunch,
              perslunchCalculated: perslunchSAP,
              lunchkorrekt: perslunchKorrekt,
            };
            // save
            store.ResultPerson.push(PersonObject);
          } else {
            // update personobject with lunch
            const result = store.ResultPerson.find(
              (el) =>
                el.persnumber === persnumber &&
                el.perskostenstelle === perskostenstelle
            );
            if (result) {
              const PersonObject: ResultPersonObject = result;
              // we remove that entry from the store by creating a new array that does not contain that entry and overwrite the store with that otherwise it will be double in the store

              /*
              const storeObjectWithEntryRemoved = store.ResultPerson.filter(
                (el) =>
                  el.persnumber !== persnumber &&
                  el.perskostenstelle === perskostenstelle
              );
              */
              //store.ResultPerson = storeObjectWithEntryRemoved;
              // now we update the previous retrieved Object and then add it to the storeObject
              PersonObject.perslunch = perslunch;
              PersonObject.perslunchCalculated = perslunchSAP;
              PersonObject.lunchkorrekt = perslunchKorrekt;
              // save
              store.ResultPerson.push(PersonObject);
            }
          }
        }
      });

      ///////////////////////////////////////////////////////////////////
      //
      ///////////////////////////////////////////////////

      store.zeroPerson.forEach((row) => {
        persnumber = row[0];
        persname = row[1];
        persart = row[3];
        perstype = row[4];
        perskostenstelle = row[5];
        perslohnCalculated = 0;
        perslohnrappen = 0;
        perssozial = 0;

        //lohn oder lunchcheck?
        if (persart == "Lohn") {
          if (store.Month == "Januar") {
            perspensum = row[6];
            perslohn = row[7];
          }
          if (store.Month == "Februar") {
            perspensum = row[8];
            perslohn = row[9];
          }
          if (store.Month == "März") {
            perspensum = row[10];
            perslohn = row[11];
          }
          if (store.Month == "April") {
            perspensum = row[12];
            perslohn = row[13];
          }
          if (store.Month == "Mai") {
            perspensum = row[14];
            perslohn = row[15];
          }
          if (store.Month == "Juni") {
            perspensum = row[16];
            perslohn = row[17];
          }
          if (store.Month == "Juli") {
            perspensum = row[18];
            perslohn = row[19];
          }
          if (store.Month == "August") {
            perspensum = row[20];
            perslohn = row[21];
          }
          if (store.Month == "September") {
            perspensum = row[22];
            perslohn = row[23];
          }
          if (store.Month == "Oktober") {
            perspensum = row[24];
            perslohn = row[25];
          }
          if (store.Month == "November") {
            perspensum = row[26];
            perslohn = row[27];
          }
          if (store.Month == "Dezember") {
            perspensum = row[28];
            perslohn = row[29];
          }

          perslohnrappen = parseFloat(gerundet(perslohn, 0.05).toFixed(2));
          if (isNaN(perslohn)) {
            perslohn = 0;
          }
          perslohnfranken = Math.trunc(perslohn);

          perslohnCalculatedFranken = 0;

          if (perslohnfranken == 0) {
            perslohnkorrekt = true;
          } else {
            perslohnkorrekt = false;
          }

          // check if person object already exists (we calculated lunch before)
          const found = store.ResultPerson.some(
            (el) =>
              el.persnumber === persnumber &&
              el.perskostenstelle === perskostenstelle &&
              el.persname === persname
          );

          if (!found) {
            // generate person object
            const PersonObject: ResultPersonObject = {
              persnumber: persnumber,
              persname: persname,
              persart: persart,
              perstype: perstype,
              perslohn: perslohn,
              perslohnCalculated: perslohnCalculated,
              perskostenstelle: perskostenstelle,
              perspensumSAP: perspensum,
              lohnkorrekt: perslohnkorrekt,
              perslohnRappen: perslohnrappen,
              perslohnFranken: perslohnfranken,
              perslohnCalculatedFranken: perslohnCalculatedFranken,
              perssozial: perssozial,
            };
            // save
            store.ResultPerson.push(PersonObject);
          } else {
            // update personobject with lohn
            const result = store.ResultPerson.find(
              (el) =>
                el.persnumber === persnumber &&
                el.perskostenstelle === perskostenstelle &&
                el.persname === persname
            );

            if (result) {
              // the Person Object
              const PersonObject: ResultPersonObject = result;

              /*
              // we remove that entry from the store by creating a new array that does not contain that entry and overwrite the store with that otherwise it will be double in the store
              const storeObjectWithEntryRemoved = store.ResultPerson.filter(
                (el) =>
                  el.persnumber !== persnumber &&
                  el.perskostenstelle === perskostenstelle
              );
              store.ResultPerson = storeObjectWithEntryRemoved;
              */

              // now we update the previous retrieved Object and then add it to the storeObject
              PersonObject.perslohn = perslohn;
              PersonObject.perslohnCalculated = perslohnCalculated;
              PersonObject.lohnkorrekt = perslohnkorrekt;
              PersonObject.perslohnRappen = perslohnrappen;
              PersonObject.perslohnFranken = perslohnfranken;
              PersonObject.perslohnCalculatedFranken =
                perslohnCalculatedFranken;
              PersonObject.perssozial = perssozial;
              // save
              store.ResultPerson.push(PersonObject);
            }
          }
        } else if (persart == "Lunch") {
          // calculate lunchcheck
          // we dont really calculate pensum

          if (store.Month == "Januar") {
            perspensum = row[6];
            perslunch = row[7];
          }
          if (store.Month == "Februar") {
            perspensum = row[8];
            perslunch = row[9];
          }
          if (store.Month == "März") {
            perspensum = row[10];
            perslunch = row[11];
          }
          if (store.Month == "April") {
            perspensum = row[12];
            perslunch = row[13];
          }
          if (store.Month == "Mai") {
            perspensum = row[14];
            perslunch = row[15];
          }
          if (store.Month == "Juni") {
            perspensum = row[16];
            perslunch = row[17];
          }
          if (store.Month == "Juli") {
            perspensum = row[18];
            perslunch = row[19];
          }
          if (store.Month == "August") {
            perspensum = row[20];
            perslunch = row[21];
          }
          if (store.Month == "September") {
            perspensum = row[22];
            perslunch = row[23];
          }
          if (store.Month == "Oktober") {
            perspensum = row[24];
            perslunch = row[25];
          }
          if (store.Month == "November") {
            perspensum = row[26];
            perslunch = row[27];
          }
          if (store.Month == "Dezember") {
            perspensum = row[28];
            perslunch = row[29];
          }

          if (perslunch == 0) {
            perslunchKorrekt = true;
          } else {
            perslunchKorrekt = false;
          }

          // check if person object already exists (we calculated lohn before)
          const found = store.ResultPerson.some(
            (el) =>
              el.persnumber === persnumber &&
              el.perskostenstelle === perskostenstelle &&
              el.persname === persname
          );
          if (!found) {
            // generate person object
            const PersonObject: ResultPersonObject = {
              persnumber: persnumber,
              persname: persname,
              persart: persart,
              perstype: perstype,
              perskostenstelle: perskostenstelle,
              perspensumSAP: perspensum,
              perslunch: perslunch,
              perslunchCalculated: perslunchSAP,
              lunchkorrekt: perslunchKorrekt,
            };
            // save
            store.ResultPerson.push(PersonObject);
          } else {
            // update personobject with lunch
            const result = store.ResultPerson.find(
              (el) =>
                el.persnumber === persnumber &&
                el.perskostenstelle === perskostenstelle &&
                el.persname === persname
            );
            if (result) {
              const PersonObject: ResultPersonObject = result;
              // we remove that entry from the store by creating a new array that does not contain that entry and overwrite the store with that otherwise it will be double in the store

              /*
              const storeObjectWithEntryRemoved = store.ResultPerson.filter(
                (el) =>
                  el.persnumber !== persnumber &&
                  el.perskostenstelle === perskostenstelle
              );
              */
              //store.ResultPerson = storeObjectWithEntryRemoved;
              // now we update the previous retrieved Object and then add it to the storeObject
              PersonObject.perslunch = perslunch;
              PersonObject.perslunchCalculated = perslunchSAP;
              PersonObject.lunchkorrekt = perslunchKorrekt;
              // save
              store.ResultPerson.push(PersonObject);
            }
          }
        }
      });

      ////////////////////////////////////////////////////////////////////////
      //
      ///////////////////////////////////////////////////

      store.calculationsdone = true;

      // SOzialabgaben pro Kostenstelle und Typ
      // und zwar so: Ein Objekt generieren mit attirbute:value. Als attribute (oder key hier) verwenden wir concatenated, also KST+Typ, im sinne von tb ATP_G-21000-06-01  das _ ist zum aufsplitten am schluss.
      // dann können wir für jede Person im ResultPerson schauen: gibts das attribute (zb WP_G-21000-06-01) bereits im Objekt? Wenn ja, dann die Sozialleistung dieser Person zum value dazuaddieren. Wenn nein gibts ein neues attribut
      const sozialabgabenKSTObject: indexedObject =
        store.sozialabgabenKSTObject;

      store.ResultPerson.forEach((person) => {
        const key = person.perstype + "_" + person.perskostenstelle;
        if (!person.perssozial) {
          person.perssozial = 0;
        }
        if (
          sozialabgabenKSTObject.hasOwnProperty(key) &&
          sozialabgabenKSTObject[key].SLCalculated
        ) {
          sozialabgabenKSTObject[key].SLCalculated =
            sozialabgabenKSTObject[key].SLCalculated + person.perssozial;
        } else if (!sozialabgabenKSTObject.hasOwnProperty(key)) {
          sozialabgabenKSTObject[key] = {
            Kostenstelle: key,
            SLCalculated: person.perssozial,
          };
        }
      });

      // now we need to compare with the values we read out from the manual

      for (const [key, value] of Object.entries(sozialabgabenKSTObject)) {
        // ATP
        for (const [objectkey, objectvalue] of Object.entries(store.SLATP)) {
          const objectkeycleaned = objectkey.replace("SL ", "");
          if (key == objectkeycleaned) {
            let slmanuell = 0;

            // get the correct SL manual value
            if (store.Month == "Januar") {
              slmanuell = parseInt(objectvalue[7]);
            }
            if (store.Month == "Februar") {
              slmanuell = parseInt(objectvalue[9]);
            }
            if (store.Month == "März") {
              slmanuell = parseInt(objectvalue[11]);
            }
            if (store.Month == "April") {
              slmanuell = parseInt(objectvalue[13]);
            }
            if (store.Month == "Mai") {
              slmanuell = parseInt(objectvalue[15]);
            }
            if (store.Month == "Juni") {
              slmanuell = parseInt(objectvalue[17]);
            }
            if (store.Month == "Juli") {
              slmanuell = parseInt(objectvalue[19]);
            }
            if (store.Month == "August") {
              slmanuell = parseInt(objectvalue[21]);
            }
            if (store.Month == "September") {
              slmanuell = parseInt(objectvalue[23]);
            }
            if (store.Month == "Oktober") {
              slmanuell = parseInt(objectvalue[25]);
            }
            if (store.Month == "November") {
              slmanuell = parseInt(objectvalue[27]);
            }
            if (store.Month == "Dezember") {
              slmanuell = parseInt(objectvalue[29]);
            }

            if (isNaN(slmanuell)) {
              slmanuell = 0;
            }

            // one is float one is int. We convert to compare
            const intValue: number = Math.trunc(value.SLCalculated);
            const slintmanuell: number = slmanuell;

            sozialabgabenKSTObject[key].SLManuell = slintmanuell;
            sozialabgabenKSTObject[key].SLCalculated = intValue;

            if (intValue == slintmanuell) {
              sozialabgabenKSTObject[key].Check = true;
            } else {
              sozialabgabenKSTObject[key].Check = false;
            }
          }
        }

        // WP
        for (const [objectkey, objectvalue] of Object.entries(store.SLWP)) {
          const objectkeycleaned = objectkey.replace("SL ", "");
          if (key == objectkeycleaned) {
            let slmanuell = 0;

            // get the correct SL manual value
            if (store.Month == "Januar") {
              slmanuell = parseInt(objectvalue[7]);
            }
            if (store.Month == "Februar") {
              slmanuell = parseInt(objectvalue[9]);
            }
            if (store.Month == "März") {
              slmanuell = parseInt(objectvalue[11]);
            }
            if (store.Month == "April") {
              slmanuell = parseInt(objectvalue[13]);
            }
            if (store.Month == "Mai") {
              slmanuell = parseInt(objectvalue[15]);
            }
            if (store.Month == "Juni") {
              slmanuell = parseInt(objectvalue[17]);
            }
            if (store.Month == "Juli") {
              slmanuell = parseInt(objectvalue[19]);
            }
            if (store.Month == "August") {
              slmanuell = parseInt(objectvalue[21]);
            }
            if (store.Month == "September") {
              slmanuell = parseInt(objectvalue[23]);
            }
            if (store.Month == "Oktober") {
              slmanuell = parseInt(objectvalue[25]);
            }
            if (store.Month == "November") {
              slmanuell = parseInt(objectvalue[27]);
            }
            if (store.Month == "Dezember") {
              slmanuell = parseInt(objectvalue[29]);
            }

            if (isNaN(slmanuell)) {
              slmanuell = 0;
            }

            // one is float one is int. We convert to compare
            const intValue: number = Math.trunc(value.SLCalculated);
            const slintmanuell: number = slmanuell;

            sozialabgabenKSTObject[key].SLManuell = slintmanuell;
            sozialabgabenKSTObject[key].SLCalculated = intValue;

            if (intValue == slintmanuell) {
              sozialabgabenKSTObject[key].Check = true;
            } else {
              sozialabgabenKSTObject[key].Check = false;
            }
          }
        }

        // QUALI
        for (const [objectkey, objectvalue] of Object.entries(store.SLQUALI)) {
          const objectkeycleaned = objectkey.replace("SL ", "");
          if (key == objectkeycleaned) {
            let slmanuell = 0;

            // get the correct SL manual value
            if (store.Month == "Januar") {
              slmanuell = parseInt(objectvalue[7]);
            }
            if (store.Month == "Februar") {
              slmanuell = parseInt(objectvalue[9]);
            }
            if (store.Month == "März") {
              slmanuell = parseInt(objectvalue[11]);
            }
            if (store.Month == "April") {
              slmanuell = parseInt(objectvalue[13]);
            }
            if (store.Month == "Mai") {
              slmanuell = parseInt(objectvalue[15]);
            }
            if (store.Month == "Juni") {
              slmanuell = parseInt(objectvalue[17]);
            }
            if (store.Month == "Juli") {
              slmanuell = parseInt(objectvalue[19]);
            }
            if (store.Month == "August") {
              slmanuell = parseInt(objectvalue[21]);
            }
            if (store.Month == "September") {
              slmanuell = parseInt(objectvalue[23]);
            }
            if (store.Month == "Oktober") {
              slmanuell = parseInt(objectvalue[25]);
            }
            if (store.Month == "November") {
              slmanuell = parseInt(objectvalue[27]);
            }
            if (store.Month == "Dezember") {
              slmanuell = parseInt(objectvalue[29]);
            }

            if (isNaN(slmanuell)) {
              slmanuell = 0;
            }

            // one is float one is int. We convert to compare
            const intValue: number = Math.trunc(value.SLCalculated);
            const slintmanuell: number = slmanuell;

            sozialabgabenKSTObject[key].SLManuell = slintmanuell;
            sozialabgabenKSTObject[key].SLCalculated = intValue;

            if (intValue == slintmanuell) {
              sozialabgabenKSTObject[key].Check = true;
            } else {
              sozialabgabenKSTObject[key].Check = false;
            }
          }
        }
      }

      // store the object
      store.sozialabgabenKSTObject = sozialabgabenKSTObject;

      resolve(true);
    } catch {
      reject("Error in calculation");
    }
  });
}
