<template>
  <div class="container">
    <table>
      <thead>
        <tr>
          <th scope="col">Nummer</th>
          <th scope="col">Name</th>
          <th scope="col">Art</th>
          <th scope="col">Typ</th>
          <th scope="col">KST</th>
          <th scope="col">PensumSAP</th>
          <th scope="col">LunchExcel</th>
          <th scope="col">LunchSAP</th>
          <th scope="col">LunchCheck</th>
          <th scope="col">Sozial</th>
          <th scope="col">LohnExcel</th>
          <th scope="col">LohnExcelGerundetRappen</th>
          <th scope="col">LohnSAPBerechnet</th>
          <th scope="col">LohnExcelAbgeschnittenFranken</th>
          <th scope="col">LohnSAPAbgeschnittenFranken</th>
          <th scope="col">LohnKontrolle</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(entry, i) in falschLohnFirst" :key="i">
          <td>{{ entry.persnumber }}</td>
          <td>{{ entry.persname }}</td>
          <td>{{ entry.persart }}</td>
          <td>{{ entry.perstype }}</td>
          <td>{{ entry.perskostenstelle }}</td>
          <td>{{ entry.perspensumSAP }}</td>
          <td>{{ entry.perslunch }}</td>
          <td>{{ entry.perslunchCalculated }}</td>
          <td>{{ entry.lunchkorrekt }}</td>
          <td>{{ entry.perssozial }}</td>
          <td>{{ entry.perslohn }}</td>
          <td>{{ entry.perslohnRappen }}</td>
          <td>{{ entry.perslohnCalculated }}</td>
          <td>{{ entry.perslohnFranken }}</td>
          <td>{{ entry.perslohnCalculatedFranken }}</td>
          <td>{{ entry.lohnkorrekt }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { store } from "../store/store";

export default {
  computed: {
    falschLohnFirst: function () {
      return store.ResultPerson.sort(
        (a, b) => Number(a.lohnkorrekt) - Number(b.lohnkorrekt)
      );
    },
  },
};
</script>

<style scoped>
th {
  display: table-cell;
  vertical-align: inherit;
  font-weight: normal;
  text-align: center;
  text-decoration: underline;
  
}
td {
  border:1px solid black;
}
tr:nth-child(even) {
  background-color: #dddddd;
}
</style>
