<template>
  <div>
    <Toast />

    <!-- Title -->
    <h1>Berechnung</h1>

    <!-- Month Selection -->
    <p>Welcher Monat wird berechnet?</p>

    <Dropdown v-model="selectedMonth" :options="months" option-label="name" placeholder="Select Month" />

    <br /><br />

    <!-- the SAP Dump File Input-->
    <p>SAP Dump File:</p>
    <p><small>Eine einzelne SAP Datei. Wird als Datenbasis für die Berechnungen verwendet.</small></p>

    <FileUpload ref="FileSelectorSAPComponent" mode="basic" name="SAPFileSelector" accept=".xlsm, .xlsx, .xls" />

    <br /><br />

    <!-- the Lehrstühle File or Files Input-->
    <p>Excel Files:</p>
    <p><small>Eine oder mehrerere Lehrstuhl-Excel-Dateien. Wichtig: Nur das erste Sheet wird berücksichtigt.</small></p>

    <FileUpload ref="FileUploadExcelComponent" name="ExcelFilesSelector" :custom-upload="true" :multiple="true"
      :show-upload-button="false" cancel-label="Clear All" accept=".xlsm, .xlsx, .xls" />
    <br />
    <br />

    <!-- Calculate Button, there will run an input check. If the input check was successful we hide the button to prevent multiple clicks on it and also show the text to wait for the results-->
    <Button v-if="!hideCalculateButton" class="calculate-button" @click.prevent="calculate()">
      Calculate
    </Button>
    <i v-if="hideCalculateButton" class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
    <p v-if="hideCalculateButton">Bitte warten, wird berechnet:</p>
    <p v-if="hideCalculateButton">
      {{ processingText }}
    </p>
    <p v-if="hideCalculateButton">
      Die Resultate werden automatisch nach Abschluss des Vorganges angezeigt
    </p>

    <br /><br />
    <!-- the Settings where they can select which Lohnarten will actually be used for the calculations-->
    <router-link :to="'/selection'">Calculation Settings</router-link>

    <ConfirmDialog></ConfirmDialog>

    <br /><br /><br /><br />

    <h5>Notes Section</h5>
    <Button v-show="showSaveButton" label="Save Notes" class="p-button-text" @click.prevent="savenotes()" />
    <em v-show="showSpinner" class="pi pi-spin pi-spinner" style="font-size: 2rem"></em>
    <br /><br />
    <Editor v-model="notesEditorContent" editor-style="height: 320px"
      style="width: 750px; margin-left: auto; margin-right: auto" @text-change="textChange">
      <template #toolbar>
        <span class="ql-formats">
          <button class="ql-header" value="1"></button>
          <button class="ql-header" value="2"></button>
        </span>
        <span class="ql-formats">
          <button class="ql-list" value="ordered"></button>
          <button class="ql-list" value="bullet"></button>
        </span>
        <span class="ql-formats">
          <button class="ql-bold"></button>
          <button class="ql-italic"></button>
          <button class="ql-underline"></button>
        </span>
      </template>
    </Editor>
  </div>
</template>

<script>
import { store } from "@/store/store";
import {
  preprocessSAPFile,
  preprocessExcelFile,
} from "@/compositions/preprocess";
import calculate from "@/compositions/caluclate.ts";
import router from "@/router";
import { useToast } from "primevue/usetoast";

export default {
  data() {
    return {
      selectedMonth: store.selectedMonth, // the Month to calculate (What the SAP Dump is from, also which row we read out from excel files)
      selectedExcelFiles: store.excelFiles,
      selectedSAPFile: store.sapFile,
      hideCalculateButton: false,
      processingText: "",
      fullList: [],
      newLohnartFound: false,
      newLohnarten: [],
      showSaveButton: false,
      showSpinner: false,
      months: [
        { name: "Januar", code: "P" },
        { name: "Februar" },
        { name: "März" },
        { name: "April" },
        { name: "Mai" },
        { name: "Juni" },
        { name: "Juli" },
        { name: "August" },
        { name: "September" },
        { name: "Oktober" },
        { name: "November" },
        { name: "Dezember" },
      ],
      notesEditorContent:
        "<div>Welcome to your notes <b>Editor</b></div><div><br>Write and save your notes here</div>",
    };
  },
  async mounted() {
    // we reset the file inputs if we come back to this (from example the setting page)
    // simply because i cannot prepopulate the file input with a file already (because of browser security reasons, otherwise you could preinput a password file or something and hide it)
    // so we reset it so it doesnt just calculate from the store without showing it as input, that would be confusing to the user from where the data is coming from
    store.sapFile = null;
    store.excelFiles = [];
    store.Month = null;

    // get editor data
    let response = await fetch("/api/editor");
    let data = await response.json();
    this.notesEditorContent = data.content;
  },
  methods: {
    textChange() {
      if (!this.showSaveButton) {
        this.showSaveButton = true;
      }
    },
    async savenotes() {
      this.showSaveButton = false;
      this.showSpinner = true;
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ content: this.notesEditorContent }),
      };
      await fetch("/api/editor", requestOptions);
      this.showSpinner = false;
      this.$toast.add({
        severity: "success",
        summary: "Success Message",
        detail: "Message Content",
        life: 3000,
      });
    },
    myUploader(event) {
      //event.files == files to upload
    },
    onMonthSelected(event) {
      // Month got selected, we sace it in the store
      this.selectedMonth = event.target.value;
      store.Month = event.target.value;
    },
    onSAPFileSelected(event) {
      // SAP File got selected, we sace it in the store
      store.sapFile = event.target.files[0];
    },
    onExcelFileSelected(event) {
      // Excel Files got selected, we sace it in the store
      store.excelFiles = event.target.files;
    },
    async calculate() {
      // input check
      if (!this.selectedMonth) {
        alert("Es wurde keinen Monat ausgewählt, bitte auswählen");
      } else if (!this.$refs.FileSelectorSAPComponent.files.length > 0) {
        alert("Es wurde keine SAP Dump Datei ausgewählt, bitte auswählen");
      } else if (!this.$refs.FileUploadExcelComponent.files.length > 0) {
        alert("Es wurde keine Excel Datei/en ausgewählt, bitte auswählen");
      } else {
        // we hide the button so it does not get clicked anymore and we show the text that calculations are in progress
        this.hideCalculateButton = true;

        // we reset the results in the store so we do not show old results
        store.ResultPerson = [];
        store.ResultKostenstelle = [];
        store.calculationsdone = false;
        store.filesdone = false;
        store.calculationsdone = false;
        store.SAP = [];
        store.Data = [];
        store.SAPLohnart = [];
        store.UniquePersonNumbers = [];
        store.SLATP = {};
        store.SLWP = {};
        store.SLQUALI = {};
        store.sozialabgabenKSTObject = {};

        store.excelFiles = this.$refs.FileUploadExcelComponent.files;
        store.sapFile = this.$refs.FileSelectorSAPComponent.files[0];
        store.Month = this.selectedMonth.name;

        // Preprocessing

        // SAP File

        // UI Feedback
        this.processingText = " Preprocessing SAP Dump File ...";
        // first preprocess / load SAP File into the storage object
        await preprocessSAPFile(store.sapFile);

        // Excel files
        for (const file of store.excelFiles) {
          // UI Feedback
          this.processingText =
            " Preprocessing Excel File " + file.name + " ...";
          // first preprocess / load SAP File into the storage object
          await preprocessExcelFile(file);
        }

        // fetching the required calculation settings from the server
        // Calculation
        // UI Feedback
        this.processingText =
          " Fetching the required calculation settings from the server ...";

        // All
        let response = await fetch("/api/lohnarten/fullList");
        let data = await response.json();
        this.fullList = data;

        // ATP
        response = await fetch("/api/lohnarten/atpLohn");
        data = await response.json();
        store.lohnCheckerATP = data;

        response = await fetch("/api/lohnarten/atpSozial");
        data = await response.json();
        store.sozialCheckerATP = data;

        // WP
        response = await fetch("/api/lohnarten/wpLohn");
        data = await response.json();
        store.lohnCheckerWP = data;

        response = await fetch("/api/lohnarten/wpSozial");
        data = await response.json();
        store.sozialCheckerWP = data;

        // Quali
        response = await fetch("/api/lohnarten/qualiLohn");
        data = await response.json();
        store.lohnCheckerQuali = data;

        response = await fetch("/api/lohnarten/qualiSozial");
        data = await response.json();
        store.sozialCheckerQuali = data;

        // Comparing Lohnarten

        store.SAPLohnart.forEach((foundLohnart) => {
          if (!this.fullList.includes(foundLohnart)) {
            this.newLohnarten.push(foundLohnart);
            this.newLohnartFound = true;
            this.fullList.push(foundLohnart);
          }
        });
        if (this.newLohnartFound) {
          const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ list: this.fullList }),
          };
          await fetch("/api/lohnarten/fullList", requestOptions);

          let promise = new Promise((resolve, reject) => {
            this.$confirm.require({
              message:
                "Folgende Neue Lohnarten wurden im SAP Dump gefunden welche das System noch nicht kennt: " +
                this.newLohnarten +
                " .Möchten Sie mit der Berechnung fortfahren, ohne die neu gefundenen Lohnarten zu berücksichtigen?",
              header: "Neue Lohnarten entdeckt",
              icon: "pi pi-exclamation-triangle",
              accept: () => {
                this.$toast.add({
                  severity: "info",
                  summary: "Confirmed",
                  detail: "Calculation resumed",
                  life: 3000,
                });
                resolve(true);
              },
              reject: () => {
                this.$toast.add({
                  severity: "error",
                  summary: "Rejected",
                  detail: "Calculation aborted, redirected to Settings",
                  life: 3000,
                });
                router.push("Selection");
                reject(false);
              },
            });
          });

          await promise;
        }

        // Calculation
        // UI Feedback
        this.processingText = " Calculating ...";

        await calculate();

        // route to result screen
        router.push("Results");
      }
    },
  },
};
</script>

<style>
.p-fileupload.p-fileupload-advanced.p-component {
  width: 30%;
  margin-left: 35%;
}

.p-badge.p-badge-warning {
  visibility: hidden;
}

.pi-spinner {
  color: mediumseagreen;
}
</style>
