
import router from "../router/index";
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      lohnCheckerATP: [],
      lohnCheckerWP: [],
      lohnCheckerQuali: [],
      sozialCheckerATP: [],
      sozialCheckerWP: [],
      sozialCheckerQuali: [],
      lohnarten: [],
      // these are kept original so we can compare when the save button is pressed which ones actually changed so we only upload those to the server
      serverATPLohn: [],
      serverWPLohn: [],
      serverQualiLohn: [],
      serverATPSozial: [],
      serverWPSozial: [],
      serverQualiSozial: [],
    };
  },
  async mounted() {
    // get all the lohnarten and calculation settings from the server
    let response = await fetch("/api/lohnarten/fullList");
    let data = await response.json();
    this.lohnarten = data;

    response = await fetch("/api/lohnarten/atpLohn");
    data = await response.json();
    this.lohnCheckerATP = data;
    this.serverATPLohn = data;

    response = await fetch("/api/lohnarten/wpLohn");
    data = await response.json();
    this.lohnCheckerWP = data;
    this.serverWPLohn = data;

    response = await fetch("/api/lohnarten/qualiLohn");
    data = await response.json();
    this.lohnCheckerQuali = data;
    this.serverQualiLohn = data;

    response = await fetch("/api/lohnarten/atpSozial");
    data = await response.json();
    this.sozialCheckerATP = data;
    this.serverATPSozial = data;

    response = await fetch("/api/lohnarten/wpSozial");
    data = await response.json();
    this.sozialCheckerWP = data;
    this.serverWPSozial = data;

    response = await fetch("/api/lohnarten/qualiSozial");
    data = await response.json();
    this.sozialCheckerQuali = data;
    this.serverQualiSozial = data;
  },
  methods: {
    showSuccess(lohnart: string, added: boolean, type: string) {
      if (added) {
        this.$toast.add({ severity: 'success', summary: 'Autosave', detail: lohnart + ' has been added to ' + type, life: 3000 });
      } else {
        this.$toast.add({ severity: 'info', summary: 'Autosave', detail: lohnart + ' has been removed from ' + type, life: 3000 });
      }
    },
    async save(lohnart: string, event: any, type: string) {
      if (this.serverATPLohn != this.lohnCheckerATP) {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ list: this.lohnCheckerATP }),
        };
        await fetch("/api/lohnarten/atpLohn", requestOptions);
        this.serverATPLohn = this.lohnCheckerATP;
        this.showSuccess(lohnart, event.target.checked, type);
      }
      if (this.serverWPLohn != this.lohnCheckerWP) {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ list: this.lohnCheckerWP }),
        };
        await fetch("/api/lohnarten/wpLohn", requestOptions);
        this.serverWPLohn = this.lohnCheckerWP;
        this.showSuccess(lohnart, event.target.checked, type);
      }
      if (this.serverQualiLohn != this.lohnCheckerQuali) {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ list: this.lohnCheckerQuali }),
        };
        await fetch("/api/lohnarten/qualiLohn", requestOptions);
        this.serverQualiLohn = this.lohnCheckerQuali;
        this.showSuccess(lohnart, event.target.checked, type);
      }
      if (this.serverATPSozial != this.sozialCheckerATP) {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ list: this.sozialCheckerATP }),
        };
        await fetch("/api/lohnarten/atpSozial", requestOptions);
        this.serverATPSozial = this.sozialCheckerATP;
        this.showSuccess(lohnart, event.target.checked, type);
      }
      if (this.serverWPSozial != this.sozialCheckerWP) {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ list: this.sozialCheckerWP }),
        };
        await fetch("/api/lohnarten/wpSozial", requestOptions);
        this.serverWPSozial = this.sozialCheckerWP;
        this.showSuccess(lohnart, event.target.checked, type);
      }
      if (this.serverQualiSozial != this.sozialCheckerQuali) {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ list: this.sozialCheckerQuali }),
        };
        await fetch("/api/lohnarten/qualiSozial", requestOptions);
        this.serverQualiSozial = this.sozialCheckerQuali;
        this.showSuccess(lohnart, event.target.checked, type);
      }
    },
    cancel() {
      router.push("Files");
    },
  },
});
